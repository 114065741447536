/**
 * Maps Record to an Array of Tuples
 * @example recordToArray({m:"Male",f:"Female"}) //returns [["Male","m"],["Female","f"]]
 */
export function recordToArray<K extends string | number | symbol, V>(record: Record<K, V>): [V, K][] {
  return (Object.keys(record) as K[]).map((key) => [record[key], key]);
}

export function checkRequiredFields(name: string, input: unknown) {
  if (!input) {
    throw new Error(`Attribute '${name}' is required`);
  }
}
